import React from 'react';
import backgroundImage from '../images/mika-landing.jpg'; // Import the image
import '../style/LandingPage.css'; // Import CSS for styling

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="scrollable-content">
        {/* Left side content that should be scrollable */}
        <div className="content-transparent">
          <div className="text-content">
            <h1>just a landing page for my dog</h1>
            <p>....</p>
            <p>that is all</p>
          </div>
        </div>
      </div>
      <div className="background-image">
        {/* Right side with the non-scrollable image */}
        <img src={backgroundImage} alt="Background" className="scrolling-image" />
      </div>
    </div>
  );
};

export default LandingPage;
